<template>
  <div style="min-height: 100vh">
    <div v-for="(b, index) in normal_bets" v-bind:key="getKey(index)">
      <MyBetsItem
        class="bets-item"
        data-toggle="collapse"
        :data-target="'#betDetails' + b.id"
        aria-expanded="false"
        :aria-controls="'betDetails' + b.id"
      >
        <!-- Bet information layout -->
        <div class="row">
          <div class="col-6 col-md-6">
            <strong>BET ID</strong> :
            <span style="color: var(--yellow)" class="bets-a-1"
              >#{{ b.code }}</span
            >
          </div>
          <div class="col-6 col-md-6">
            <strong>STATUS</strong> :
            <!-- <strong v-show="getStatusString(b.won, b.status) === 'Lost'" style="color: red; text-decoration: underline;"
              class="bets-a-1">
              {{ getStatusString(b.won, b.status) }}
            </strong> -->
            <!-- <strong v-show="getStatusString(b.won, b.status) === 'Pending'"
              style="color: var(--yellow); text-decoration: underline;" class="bets-a-1">
              {{ getStatusString(b.won, b.status) }}
            </strong> -->
            <!-- <strong v-show="getStatusString(b.won, b.status) === 'Won'"
              style="color: #009D80; text-decoration: underline;" class="bets-a-1">
              {{ getStatusString(b.won, b.status) }}
            </strong> -->

            <strong
              v-if="b.processing_status == 0"
              style="color: var(--yellow); text-decoration: underline"
              class="bets-a-1"
              >Pending</strong
            >
            <strong
              v-else-if="b.processing_status == 1"
              style="color: red; text-decoration: underline"
              class="bets-a-1"
              >Lost</strong
            >
            <strong
              v-else-if="b.processing_status == 2"
              style="color: #009d80; text-decoration: underline"
              class="bets-a-1"
              >Won</strong
            >
            <strong
              v-else-if="b.processing_status == 3"
              style="color: var(--yellow); text-decoration: underline"
              class="bets-a-1"
              >Voided</strong
            >
            <strong
              v-else-if="b.processing_status == -1"
              style="color: var(--yellow); text-decoration: underline"
              class="bets-a-1"
              >Cancelled</strong
            >
            <strong
              v-else-if="b.processing_status == 5"
              style="color: #009d80; text-decoration: underline"
              class="bets-a-1"
              >Cashout</strong
            >
          </div>
        </div>
        <br />
        <!-- Additional bet details -->
        <div class="row">
          <div class="col-6 col-md-6">
            <strong>POSSIBLE WIN</strong> :
            <span style="color: var(--yellow)" class="bets-a-1">{{
              b.possible_win | currency
            }}</span>
          </div>
          <div class="col-6 col-md-6">
            <strong>STAKE AMOUNT</strong> :
            <span style="color: var(--yellow)" class="bets-a-1">{{
              b.stake | currency
            }}</span>
          </div>
        </div>
      </MyBetsItem>
      <div class="accordion" id="betsAccordion">
        <div
          style="
            border: none;
            margin-top: 4px;
            margin-bottom: 4px;
            background-color: #02032f;
          "
          class="card"
        >
          <div
            :id="'betDetails' + b.id"
            class="collapse"
            :aria-labelledby="'heading' + b.id"
            data-parent="#betsAccordion"
          >
            <div
              style="background-color: #02032f; border-radius: 0.5rem"
              class="card-body"
            >
              <!-- Bet Details Content -->
              <div class="row">
                <div class="col-6 col-md-6">
                  BET ID :
                  <span class="bets-a-1 text-light">#{{ b.code }}</span>
                </div>
                <div class="col-6 col-md-6">
                  Stake :
                  <span class="bets-a-1 text-light">{{
                    b.stake | currency
                  }}</span>
                </div>
                <div class="col-6 col-md-6">
                  Possible Win :
                  <span class="bets-a-1 text-light">{{
                    b.possible_win | currency
                  }}</span>
                </div>
                <div class="col-6 col-md-6">
                  Status :
                  <!-- <span class="bets-a-1 text-light">{{ getStatusString(b.won, b.status) }}</span> -->

                  <span
                    v-if="b.processing_status == 0"
                    style="color: var(--yellow)"
                    class="bets-a-1"
                    >Pending</span
                  >
                  <span
                    v-else-if="b.processing_status == 1"
                    style="color: red"
                    class="bets-a-1"
                    >Lost</span
                  >
                  <span
                    v-else-if="b.processing_status == 2"
                    style="color: #009d80"
                    class="bets-a-1"
                    >Won</span
                  >
                  <span
                    v-else-if="b.processing_status == 3"
                    style="color: var(--yellow)"
                    class="bets-a-1"
                    >Voided</span
                  >
                  <span
                    v-else-if="b.processing_status == -1"
                    style="color: var(--yellow)"
                    class="bets-a-1"
                    >Cancelled</span
                  >
                  <span
                    v-else-if="b.processing_status == 5"
                    style="color: #009d80"
                    class="bets-a-1"
                    >Cashout</span
                  >
                </div>
              </div>
              <!-- Other Bet Details Content -->
              <h6 class="text-light mt-3">Matches won</h6>
              <span style="color: var(--yellow)"
                >{{ b.won_games }} out of {{ b.bets }}</span
              >

              <h6 class="text-light mt-3">Matches</h6>
              <div v-for="(p, index) in b.picked" :key="index">
                <!-- Match information layout -->
                <div class="row">
                  <div class="col-6 col-md-6">
                    <span class="bets-a-1 text-light">{{
                      p.competition || "N/A"
                    }}</span>
                  </div>
                  <div class="col-6 col-md-6">
                    <span>Bet Date: </span>
                    <span class="bets-a-1 text-light">{{
                      formatMatchDate(b.created)
                    }}</span>
                  </div>
                  <div class="col-6 col-md-6">
                    <span class="bets-a-1 text-light">{{ p.competition }}</span>
                  </div>
                  <div class="col-6 col-md-6">
                    <span>Match Date: </span>
                    <span class="bets-a-1 text-light">{{
                      formatMatchDate(p.match_date)
                    }}</span>
                  </div>
                  <h6 class="col-12 text-light mt-3">Results</h6>
                  <div class="col-12 col-md-12">
                    Pick :
                    <span class="bets-a-1 text-light">{{
                      p.outcome_name
                    }}</span>
                  </div>
                  <div class="col-12 col-md-12">
                    <span class="bets-a-1 text-light"
                      >{{ getHomeCompetitorName(p.match_name) }} VS
                      {{ getAwayCompetitorName(p.match_name) }}</span
                    >
                  </div>
                  <div class="col-12 col-md-12">
                    Market name :
                    <span class="bets-a-1 text-light">{{ p.market_name }}</span>
                  </div>
                  <div class="col-12 col-md-12">
                    Status :
                    <span v-if="p.fixture_status">
                      <span v-if="parseInt(p.status) === 0">- : -</span>
                      <span class="small-text bets-a-1 text-light" v-else
                        >{{ p.fixture_status.home_score }} -
                        {{ p.fixture_status.away_score }}</span
                      >
                    </span>
                    <span class="bets-a-1 text-light">{{
                      getStatusName(p)
                    }}</span>
                  </div>
                  <div class="col-12 text-left small-text">
                    Selected odd :
                    <span style="color: var(--white)" class="odds-bts">{{
                      p.odd | formatOdds
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import walletserve from "@/services/walletserve";
import axios from "@/services/api";
import bettingserve from "@/services/bettingserve";
import jpserve from "@/services/jpserve";

import MyBetsItem from "./MyBetsItem.vue";

export default {
  name: "SortDeposit",
  data: () => ({
    transaction_id: null,
    message: null,
    amount: 0,
    loading: "",
    bet: {},
    bets: [],
    code: "",
    msg: "",
    share_odds: "",
    copyText: "Copy Code",
    copyBookingCode: "Click to copy",
    bustabets: [],
    view_more: false,
    filter_status: 0, // 0 - all, 1 - active , 2 - won , 3 - lost , 4 - cancelled,
    all: 0,
    allbusta: 0,
    pending: 0,
    won: 0,
    lost: 0,
    voided: 0,
    cancelled: 0,
    cashout: 0,
    className: "",
    collapseMybet: "collapseMybet",
    normal_bets: [],
    cashout_bets: [],
    jackpot_bets: [],
    jackpotResults: [],
    free_bets: [],
    active_menu: "normal_bets",
    cashoutLoading: "",
  }),
  components: {
    MyBetsItem,
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        // react to route changes...
        document.title = "My Bets";
        document.description = "Manage Bets and Results";
      },
    },
  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "bets");
    this.myBets();
    this.myFreeBets();
    this.getJackpotResults();
    // this.bustaBets();
    this.reloadProfile();
    this.setFilter(-10);
    window.$(this.$refs.jisortModal).on("hidden.bs.modal", this.reset);
  },

  methods: {
    getDataTarget: function (id) {
      return "#collapseMybet" + id;
    },
    getArialControl: function (id) {
      return "collapseMybet" + id;
    },

    isActive: function (menu) {
      return this.active_menu === menu ? "active" : "";
    },
    setActive: function (menu) {
      this.active_menu = menu;
    },
    getStatusString(won, processing_status) {
      switch (processing_status) {
        case 0:
          return "Pending";
        case 1:
          return "Lost";
        case 2:
          return "Won";
        case 3:
          return "Voided";
        case -1:
          return "Cancelled";
        case 5:
          return "Cashout";
        default:
          return "Error";
      }
    },
    getBetFilterStatus: function (bet) {
      switch (parseInt(bet.status)) {
        case 0:
          return "Pending";

        case 1:
          return "Lost";

        case 2:
          return "Won";

        case 3:
          return "Voided";

        case 4:
          return "Cancelled";

        case 5:
          return "Cashout";
      }
    },

    shareBet: function (code, odds) {
      var vm = this;
      var ob = {
        code: code,
        odds: odds,
      };

      // console.log(JSON.stringify(ob));
      vm.EventBus.$emit("share:bet", ob);
    },
    copyShareBetLink: function (code) {
      var link = "https://bahatibet.waliliana.co.ke/share/" + code;
      this.copyToClipboard(link);
      this.copyText = "Copied";

      // Reset the button text back to "Copy Code" after 2 seconds
      setTimeout(() => {
        this.copyText = "Copy Code";
      }, 2000);
    },

    copyToClipboard: function (text) {
      if (navigator.clipboard) {
        // Use the modern clipboard API
        navigator.clipboard
          .writeText(text)
          .then(() => {
            console.log("Copied to clipboard successfully!");
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
          });
      } else {
        // Fallback for older browsers
        var textArea = document.createElement("textarea");
        textArea.value = text;
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand("copy");
          console.log(
            "Fallback: Copying text command was " +
              (successful ? "successful" : "unsuccessful")
          );
        } catch (err) {
          console.error("Fallback: Unable to copy", err);
        }

        document.body.removeChild(textArea);
      }
    },
    getCounterClass: function (status) {
      if (parseInt(status) === parseInt(this.filter_status)) {
        return "bets-counter-selected";
      }

      return "bets-counter-unselected";
    },
    getHomeCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }

      return matchName.split("vs.")[0];
    },
    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },
    getMatchClass: function (picked) {
      if (parseInt(picked.status) === 0) {
        return "bd-callout-pending";
      }

      if (parseInt(picked.status) === 1) {
        return "bd-callout-pending";
      }

      if (parseInt(picked.status) === -1) {
        return "bd-callout-cancel";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {
        return "bd-callout-won";
      }

      if (parseInt(picked.status) === 3 && parseInt(picked.won) === 1) {
        return "bd-callout-won";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 0) {
        return "bd-callout-lost";
      }
    },
    viewAll: function () {
      this.view_more = false;
    },
    setFilter: function (filter_status) {
      this.filter_status = filter_status;
    },
    getMyBetLabelClass: function (bet) {
      switch (parseInt(bet.filter_status)) {
        case 0:
          return "bet-text-pending";

        case 1:
          return "bet-text-lost";

        case 2:
          return "bet-text-won";

        case 3:
          return "bet-text-voided";

        case 4:
          return "bet-text-cancelled";

        case 5:
          return "bet-text-won";
      }

      return "";
    },
    getBetStatusName: function (bet) {
      switch (parseInt(bet.filter_status)) {
        case 0:
          return "Pending";

        case 1:
          return "Lost";

        case 2:
          return "Won";

        case 3:
          return "Voided";

        case 4:
          return "Cancelled";

        case 5:
          return "Cashout";
      }

      return "";
    },
    getBetsBorderClass: function (bet) {
      switch (parseInt(bet.filter_status)) {
        case 0:
          return "bets-item-pending";

        case 1:
          return "bets-item-lost";

        case 2:
          return "bets-item-won";

        case 3:
          return "bets-item-voided";

        case 4:
          return "bets-item-cancelled";

        case 5:
          return "bets-item-won";
      }

      return "";
    },

    getJackpotResults() {
      this.reset();
      var vm = this;
      var m = this.getProfile();
      var p = m.a;

      if (!p) {
        this.$toast.open({
          message: "Please login to proceed",
          type: "error",
          position: "top",
        });

        this.$router.push({ name: "login", params: {} });
        return;
      }

      // Fetch results for both category IDs (5 and 6)
      var mb8Path = `${process.env.VUE_APP_BASE_JACKPOT_URL}/jackpot/5/bet`;
      var jazikaPath = `${process.env.VUE_APP_BASE_JACKPOT_URL}/jackpot/6/bet`;

      Promise.all([
        jpserve.get(mb8Path, { headers: { "api-key": vm.getAuth() } }),
        jpserve.get(jazikaPath, { headers: { "api-key": vm.getAuth() } }),
      ])
        .then(([mb8Response, jazikaResponse]) => {
          if (mb8Response.data && mb8Response.data.length > 0) {
            // Add a property indicating the source for each result
            mb8Response.data.forEach(
              (result) => (result.source = "MB8 MIDWEEK JACKPOT")
            );
            jazikaResponse.data.forEach(
              (result) => (result.source = "JAZIKA MEGA JACKPOT")
            );

            // Combine results from both category IDs into one array
            this.jackpotResults = [...mb8Response.data, ...jazikaResponse.data];
          } else if (jazikaResponse.data && jazikaResponse.data.length > 0) {
            // Add a property indicating the source for each result
            jazikaResponse.data.forEach(
              (result) => (result.source = "JAZIKA MEGA JACKPOT")
            );

            // Set jackpot results to only JAZIKA jackpot results
            this.jackpotResults = jazikaResponse.data;
          } else {
            // No jackpot results available
            this.$toast.open({
              message: "No jackpot results available",
              type: "error",
              position: "top",
            });
          }

          // Sort the jackpotResults array by the 'created' date in descending order
          this.jackpotResults.sort(
            (a, b) => new Date(b.created) - new Date(a.created)
          );
        })
        .catch((error) => {
          console.error("Error fetching jackpot results:", error);
        });
    },

    toggleCollapse(jackpotId) {
      const elementId = "jackpot_" + jackpotId;
      const element = document.getElementById(elementId);
      if (element.classList.contains("show")) {
        element.classList.remove("show");
      } else {
        element.classList.add("show");
      }
    },

    myBets: function () {
      this.reset();
      var vm = this;
      var m = this.getProfile();
      var p = m.a;

      if (!p) {
        this.$toast.open({
          message: "Please login to proceed",
          type: "error",
          position: "top",
        });
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var path = process.env.VUE_APP_BASE_BETTING_URL + "/bet";

      // console.log("path");

      bettingserve
        .get(path, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((response) => {
          console.log("Response in mybets, my bets page", response);
          var betsData = response.data.data;
          vm.bets = betsData;

          // Reset counters
          vm.all =
            vm.pending =
            vm.lost =
            vm.won =
            vm.voided =
            vm.cancelled =
            vm.cashout =
              0;

          betsData.forEach((bet) => {
            // Update counters based on processing_status
            switch (bet.processing_status) {
              case 0:
                vm.pending++;
                break;
              case 1:
                vm.lost++;
                break;
              case 2:
                vm.won++;
                break;
              case 3:
                vm.voided++;
                break;
              case -1:
                vm.cancelled++;
                break;
              case 5:
                vm.cashout++;
                break;
            }

            // Additional properties for each bet
            bet.filter_status = bet.processing_status;
            bet.statusName = vm.getStatusString(bet.processing_status); // Using the provided method to get status name
            bet.betLabelClass = vm.getMyBetLabelClass(bet);
            bet.borderClass = vm.getBetsBorderClass(bet);

            // Separate bets into categories
            if (bet.processing_status === 5) {
              vm.cashout_bets.push(bet);
            } else if (bet.bet_type === 4) {
              vm.jackpot_bets.push(bet);
            } else {
              vm.normal_bets.push(bet);
            }
          });

          // Total count of all bets
          vm.all = betsData.length;
        })
        .catch((error) => {
          console.error("Error fetching bets:", error);
          vm.loading = "";

          if (error.response) {
            if ([401, 400, 428].includes(error.response.status)) {
              this.$toast.open({
                message: "Your session on this device has expired",
                type: "error",
                position: "top",
              });
              return;
            } else {
              this.$toast.open({
                message: error.response.data.message,
                type: "error",
                position: "top",
              });
            }
          } else {
            // vm.setError(
            //   "Failed",
            //   "An error occurred while fetching data. Please try again later."
            // );
          }
        });
    },

    myFreeBets: function () {
      this.reset();
      var vm = this;
      var m = this.getProfile();
      var p = m.a;

      if (!p) {
        this.$toast.open({
          message: "Please login to proceed",
          type: "error",
          position: "top",
        });
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var path = process.env.VUE_APP_BASE_FREEBET_URL + "/bet";

      // Debugging: Log the API key and path
      console.log("Fetching free bets from:", path);
      console.log("Using API key:", vm.getAuth());

      bettingserve
        .get(path, {
          headers: {
            "api-key": vm.getAuth(), // Ensure the correct API key is used
          },
        })
        .then((response) => {
          console.log("Response in myfreebets", response);
          var betsData = response.data;
          vm.bets = betsData;

          // Reset counters
          vm.all =
            vm.pending =
            vm.lost =
            vm.won =
            vm.voided =
            vm.cancelled =
            vm.cashout =
              0;

          betsData.forEach((bet) => {
            // Update counters based on processing_status
            switch (bet.processing_status) {
              case 0:
                vm.pending++;
                break;
              case 1:
                vm.lost++;
                break;
              case 2:
                vm.won++;
                break;
              case 3:
                vm.voided++;
                break;
              case -1:
                vm.cancelled++;
                break;
              case 5:
                vm.cashout++;
                break;
            }

            // Additional properties for each bet
            bet.filter_status = bet.processing_status;
            bet.statusName = vm.getStatusString(bet.processing_status); // Using the provided method to get status name
            bet.betLabelClass = vm.getMyBetLabelClass(bet);
            bet.borderClass = vm.getBetsBorderClass(bet);

            // Separate bets into categories
            if (bet.bet_type === 1) {
              vm.free_bets.push(bet);
            } else {
              vm.normal_bets.push(bet);
            }
          });

          // Total count of all bets
          vm.all = betsData.length;
        })
        .catch((error) => {
          console.error("Error fetching bets:", error);
          vm.loading = "";

          if (error.response) {
            // Log the full error response
            console.log("Error response:", error.response);

            if ([401, 400, 428].includes(error.response.status)) {
              this.$toast.open({
                message: "Your session on this device has expired",
                type: "error",
                position: "top",
              });
              return;
            } else {
              this.$toast.open({
                message: error.response.data.message,
                type: "error",
                position: "top",
              });
            }
          } else {
            // vm.setError(
            //   "Failed",
            //   "An error occurred while fetching data. Please try again later."
            // );
          }
        });
    },

    requestCashout: function (bet_id) {
      this.reset();

      var p = this.getProfile();
      if (!p) {
        this.$toast.open({
          message: "Please login to proceed",
          type: "error",
          position: "top",
        });
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var vm = this;
      var path = process.env.VUE_APP_URL_REQUEST_CASHOUT.replace(
        "{profile_id}",
        p.d
      );

      var data = {
        bet_id: bet_id,
      };

      vm.cashoutLoading = "loading";

      axios
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          //var msg = res;
          var amount = res.data.message.amount;
          //var description = res.data.message.description;
          // console.log("GOT cashout amount here " + JSON.stringify(amount));

          //text: "Cashout this bet at Ksh. "+vm.formatCurrency(amount),

          vm.$swal
            .fire({
              title: "Cashout this bet at Ksh. " + vm.formatCurrency(amount),
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Cashout",
            })
            .then((result) => {
              if (result.isConfirmed) {
                var path = process.env.VUE_APP_URL_ACCEPT_CASHOUT.replace(
                  "{profile_id}",
                  p.d
                );

                var data = {
                  bet_id: bet_id,
                };

                axios
                  .post(path, JSON.stringify(data), {
                    headers: {
                      "api-key": vm.getAuth(),
                    },
                  })
                  .then((res) => {
                    vm.cashoutLoading = "";

                    console.log("RESPONSE ==> " + JSON.stringify(res));

                    vm.$swal.fire(
                      "Submitted!",
                      "Your cashout is being processed, you will be notified shortly",
                      "success"
                    );
                  })
                  .catch((err) => {
                    vm.cashoutLoading = "";

                    vm.$swal.fire(
                      "Failed!",
                      "Error occurred processing your cashout request",
                      "error"
                    );

                    if (err.response) {
                      var message = "";

                      if (parseInt(err.response.status) === 428) {
                        message = err.response.data.message;
                        this.$toast.open({
                          message: message,
                          type: "error",
                          position: "top",
                        });

                        return;
                      }

                      if (
                        parseInt(err.response.status) === 401 ||
                        parseInt(err.response.status) === 400
                      ) {
                        this.$toast.open({
                          message: "Your session on this device has expired",
                          type: "error",
                          position: "top",
                        });

                        // vm.logout();
                        return;
                      } else {
                        message = err.response.data.message;
                        this.$toast.open({
                          message: message,
                          type: "error",
                          position: "top",
                        });
                      }
                    } else if (err.request) {
                      this.$toast.open({
                        message: "Please check your network",
                        type: "error",
                        position: "top",
                      });
                      // console.log(JSON.stringify(err.request));
                    } else {
                      //vm.setError("Failed", "Please check your network")
                      // console.log(JSON.stringify(err));
                    }
                  });
              } else {
                vm.cashoutLoading = "";
              }
            });
        })
        .catch((err) => {
          vm.cashoutLoading = "";

          console.log("RESPONSE HERE " + JSON.stringify(err));
          vm.$swal.fire(
            "Failed!",
            "Cashout for your bet is not available at the moment",
            "error"
          );
        });
    },

    // bustaBets: function () {
    //   this.reset();
    //   var m = this.getProfile();
    //   var p = m.a;
    //
    //   if (!p) {
    //     this.$toast.open({
    //   message: 'Please login to proceed',
    //   type: 'error',
    //   position: 'top'
    // });
    //     this.$router.push({ name: "login", params: {} });
    //     return;
    //   }
    //
    //   var vm = this;
    //   var path = process.env.VUE_APP_BASE_BETTING_URL + '/bet';
    //
    //   bettingserve()
    //       .get(path, {
    //         headers: {
    //           "api-key": vm.getAuth(),
    //         },
    //       })
    //       .then((res) => {
    //         console.log("Response", res);
    //         // vm.bustabets = msg;
    //         // console.log(JSON.stringify(vm.bustabets));
    //         vm.allbusta = vm.bustabets.length;
    //       })
    //       .catch((err) => {
    //         vm.loading = "";
    //
    //         if (err.response) {
    //           if (
    //               parseInt(err.response.status) === 401 ||
    //               parseInt(err.response.status) === 400 ||
    //               parseInt(err.response.status) === 428
    //           ) {
    //             vm.setError(
    //                 "Session Expired",
    //                 "Your session on this device has expired"
    //             );
    //             // vm.logout();
    //             return;
    //           } else {
    //             // console.log(JSON.stringify(err.response.data.message));
    //           }
    //         } else if (err.request) {
    //           // console.log(JSON.stringify(err.request));
    //         } else {
    //           // console.log(JSON.stringify(err));
    //         }
    //       });
    // },

    selectedClass: function (filter_status) {
      return this.filter_status === filter_status
        ? "bets-tab-text btn-selected"
        : "bets-tab-text btn-normal";
    },
    viewBet: function (bet) {
      this.view_more = true;

      if (
        typeof bet.fixture_status === undefined ||
        bet.fixture_status === undefined
      ) {
        bet.fixture_status = {};
        bet.fixture_status.home_score = "-";
        bet.fixture_status.away_score = "-";
      }

      this.bet = bet;
    },
    getBorderClass: function (picked) {
      if (parseInt(picked.status) === 0) {
        return "bets-item-pending";
      }

      if (parseInt(picked.status) === 1) {
        return "bets-item-pending";
      }

      if (parseInt(picked.status) === -1) {
        return "bets-item-cancel";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {
        return "bets-item-won";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 0) {
        return "bets-item-lost";
      }

      return "";
    },

    getStatusName: function (picked) {
      if (parseInt(picked.status) === 0) {
        return "Pending";
      }

      if (parseInt(picked.status) === 1) {
        return "Pending";
      }

      if (parseInt(picked.status) === -1) {
        return "Cancelled";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {
        return "Won";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 0) {
        return "Lost";
      }

      if (parseInt(picked.status) === 3 && parseInt(picked.won) === 1) {
        return "Cashout";
      }

      return "";
    },

    getStatusName2: function (picked) {
      if (parseInt(picked.status) === 0) {
        return "Pending";
      }

      if (parseInt(picked.status) === 1) {
        return "Lost";
      }

      // if (parseInt(picked.status) === -1) {
      //   return "Cancelled";
      // }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 2) {
        return "Won";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 3) {
        return "Voided";
      }

      if (parseInt(picked.status) === 3 && parseInt(picked.won) === 4) {
        return "Cancelled";
      }

      return "";
    },
    getBetLabelClass: function (picked) {
      if (parseInt(picked.status) === 0) {
        return "bet-text-pending";
      }

      if (parseInt(picked.status) === 1) {
        return "bet-text-pending";
      }

      if (parseInt(picked.status) === -1) {
        return "bet-text-cancel";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {
        return "bet-text-won";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 0) {
        return "bet-text-lost";
      }

      if (parseInt(picked.status) === 3 && parseInt(picked.won) === 1) {
        return "bet-text-won";
      }

      return "";
    },
    openCity: function (cityName) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");

      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      tablinks = document.getElementsByClassName("tablinks");

      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }

      if (document.getElementById(cityName)) {
        document.getElementById(cityName).style.display = "block";
      }

      this.className += " active";
    },
    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "unique-id-" + index + "-");
    },

    reset() {
      this.transaction_id = null;
      this.message = null;
      this.loading = false;
    },

    jisort() {
      this.message = null;
      var p = this.getAuth();
      if (!p) {
        this.$toast.open({
          message: "Please login to proceed",
          type: "error",
          position: "top",
        });
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var vm = this;
      var path = "/deposit/reconcile";

      var data = {
        transaction_id: this.transaction_id,
      };

      this.loading = true;

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          var msg = res.data.data;
          this.message = {
            message: msg,
            type: "success",
          };
        })
        .catch((err) => {
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              this.$toast.open({
                message: "Your session on this device has expired",
                type: "error",
                position: "top",
              });
              // vm.logout();
              return;
            } else {
              this.message = {
                message: err.response.data.error_message ?? "Failed",
                type: "error",
              };
            }
          } else if (err.request) {
            this.message = {
              message:
                "Network Error. Check your network connection and try again",
              type: "error",
            };
          } else {
            this.message = {
              message: "An error occurred. Please try again",
              type: "error",
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    profile: function () {
      return this.getProfile();
    },
    filteredBets: function () {
      var vm = this;

      if (vm.filter_status === -10) {
        return this.bets;
      }

      var bets = [];
      this.jQuery.each(this.bets, function (k, v) {
        if (parseInt(v.processing_status) === parseInt(vm.filter_status)) {
          bets.push(v);
        }
      });

      return bets;
    },
    borderClass: function () {
      switch (parseInt(this.filter_status)) {
        case 0:
          return "bets-item-pending";

        case 1:
          return "bets-item-lost";

        case 2:
          return "bets-item-won";

        case 3:
          return "bets-item-voided";

        case 4:
          return "bets-item-cancelled";
      }

      return "";
    },
    statusName: function () {
      switch (parseInt(this.filter_status)) {
        case 0:
          return "Pending";

        case 1:
          return "Lost";

        case 2:
          return "Won";

        case 3:
          return "Voided";

        case 4:
          return "Cancelled";
      }

      return "";
    },
    betLabelClass: function () {
      switch (parseInt(this.filter_status)) {
        case 0:
          return "bet-text-pending";

        case 1:
          return "bet-text-lost";

        case 2:
          return "bet-text-won";

        case 3:
          return "bet-text-voided";

        case 4:
          return "bet-text-cancelled";
      }

      return "";
    },
  },
  filters: {
    currency: function (amount) {
      if (amount == null) {
        amount = 0;
      }

      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "KES",
      });
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }

      return parseFloat(x).toFixed(2);
    },
  },
};
</script>

<style scoped>
.form,
.wallet-modal {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.deposit-modal {
  background: rgba(0, 0, 0, 0.5);
}

.alert {
  position: relative;
}

.wallet-modal {
  background: var(--primary);
  padding: 1rem;
  border-radius: 0.5rem;
}

.modal-dialog {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-content {
  margin-top: auto;
  background: none;
}

.md-title {
  display: flex;
  flex-direction: column;
}

.md-title h4 {
  margin: 0;
}

.md-title span {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
}
</style>
